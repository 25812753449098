import request from '@/utils/request'

export function deleteFile (id) {
  return request({
    url: '/backend/file/delete',
    method: 'delete',
    params: { id }
  })
}

export function fetchList (ids) {
  return request({
    url: '/backend/file/list',
    method: 'get',
    params: { ids }
  })
}

export function downloadFile (id) {
  return request({
    url: '/backend/file/get',
    method: 'get',
    responseType: 'blob',
    params: { id }
  })
}

/**
 * @param params
 * params.type:
 *   1 - Общий,
 *   2 - Индивидуальный,
 *   2 - КНД,
 * params.period_id: За какой период
 */
export function getExport (params) {
  return request({
    url: '/backend/file/get-export',
    method: 'get',
    responseType: 'blob',
    params: params
  })
}

/**
 * @param params
 * params.type:
 *   1 - Общий,
 *   2 - Индивидуальный,
 *   2 - КНД,
 */
export function getExportJournal (params) {
  return request({
    url: '/backend/file/get-export-journal',
    method: 'get',
    responseType: 'blob',
    params: params
  })
}

export function getExportKP (params) {
  return request({
    url: '/backend/file/get-export-kp',
    method: 'get',
    responseType: 'blob',
    params: params
  })
}

export function getPrintKP (params) {
  return request({
    url: '/backend/file/get-print-kp',
    method: 'get',
    responseType: 'blob',
    params: params
  })
}

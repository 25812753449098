<template>
  <div>
    <el-upload
      :v-loading="loading"
      ref="file"
      action="/backend/file/upload"
      :limit="limit"
      :on-preview="onPreview"
      :before-remove="beforeRemove"
      :on-remove="handleRemove"
      :on-exceed="handleExceed"
      :before-upload="beforeUpload"
      :headers="{Authorization: 'Bearer ' + token}"
      :file-list="uploadedFiles"
      :accept="mimeType"
      :disabled="disabled"
      :on-success="fileUploaded">
      <el-button
        v-if="!disabled"
        slot="trigger"
        size="small"
        type="primary">
        Добавить файл
      </el-button>
    </el-upload>
  </div>
</template>

<script>

import { downloadFile, fetchList } from '@/api/file'
import { mapGetters } from 'vuex'

export default {
  props: {
    disabled: {
      default: false
    },
    value: {
      default: null
    },
    mimeType: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: 5
    }
  },
  data () {
    return {
      loading: false,
      deleteWithoutMessage: false,
      uploadedFiles: []
    }
  },
  computed: {
    ...mapGetters(['token']),
    fileSpell () {
      return this.limit === 1 ? 'файла' : 'файлов'
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      if (this.value) {
        const fileIds = []
        for (const item of this.value) {
          if (!item.deleted_at) {
            fileIds.push(item.id)
          }
        }
        if (fileIds.length > 0) {
          fetchList(fileIds).then(({ data }) => {
            this.uploadedFiles = data
          })
        }
      }
    },
    handleRemove (file) {
      if (file.id) {
        this.$emit('delete', file.id)
      }
    },
    handleExceed () {
      this.$message.warning(`Можно приложить не больше ${this.limit} ${this.fileSpell}`)
    },
    beforeUpload (file) {
      if (this.mimeType) {
        if (file.type === this.mimeType) {
          return true
        }
        this.deleteWithoutMessage = true
        return false
      }
      return true
    },
    beforeRemove (file) {
      if (this.deleteWithoutMessage) {
        this.deleteWithoutMessage = false
        return true
      }
      return this.$confirm(`Вы уверены, что хотите удалить ${file.name}?`)
    },
    fileUploaded (data, file) {
      file.id = data
      this.$emit('file-uploaded', data)
    },
    onPreview (file) {
      let id = null
      if (file.id) {
        id = file.id
      } else if (file.response) {
        id = file.response
      }
      downloadFile(id).then(({ data }) => {
        const url = URL.createObjectURL(new Blob([data], { type: data.type }))
        if (data.type === 'application/pdf') {
          window.open(url, '_blank')
        } else {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display: none'
          a.href = url
          a.download = file.name
          a.target = '_blank'
          a.click()
        }
      })
    }
  }
}
</script>
